import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth } from '../../AuthContext';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';

function SaveDialog({
  t, globalAssessmentState, graphicsLayer, open, setOpen, setAssessmentId, setSavedAuthor, existingAssessment, setExistingAssessment, setSharedUsers
}) {
  const { email, jwtToken } = useAuth();
  const [saving, setSaving] = useState(false);
  const [saveErrorMessage, setSaveErrorMessage] = useState('');

  const handleErrorResponse = (response) => {
    if (response.status === 413) {
      // set error message to a standard 413 text for payload-too-large errors:
      setSaveErrorMessage(t('saveDialog.errorMessages.413'));
      setSaving(false);
    } else {
      response.json()
        .then((body) => {
          if (body.msg) {
            // set error message to response body if it can be parsed and has a msg attribute:
            setSaveErrorMessage(body.msg);
          } else {
            // if it doesn't have a msg attribute, use the status and status text:
            setSaveErrorMessage(`${response.status} ${response.statusText}`);
          }
          setSaving(false);
        })
        .catch(() => {
          // if the body can't be parsed, use the status and status text:
          setSaveErrorMessage(`${response.status} ${response.statusText}`);
          setSaving(false);
        });
    } 
  }

  const closeDialog = () => {
    setOpen(false);
    setSaveErrorMessage('')
  }
  
  const save = (state) => {
    const stateToSave = {
      ...state,
      lastModified: Date.now(),
      graphics: graphicsLayer.graphics.toArray().map((graphic) => graphic.toJSON())
    };
    const payloadSize = new Blob([JSON.stringify(stateToSave)]).size;
    // There seems to be a problem with payload sizes slightly larger than 4.5 MB.
    // Rather than spending time to find the exact threshhold, we're rounding down.
    const MAX_PAYLOAD_SIZE = 4500000;
    if (payloadSize > MAX_PAYLOAD_SIZE) {
      setSaveErrorMessage(t('saveDialog.errorMessages.413'));
    } else {
      setSaveErrorMessage('');
      setSaving(true);
      fetch(`${process.env.REACT_APP_CCVI_API_URL}/assessment/${state.author}/${state.assessmentId}`,
        {
          method: 'PUT',
          headers: { Authorization: `Bearer ${jwtToken}`, 'Content-Type': 'application/json' },
          body: JSON.stringify(stateToSave)
        })
        .then((res) => {
          if (res.ok) {
            closeDialog();
            setExistingAssessment(true);
            setSaving(false);
          } else {          
            handleErrorResponse(res);
          }
        })
        .catch((e) => {
          // if there is some other error fetching, use it as the error message:
          console.warn(e);
          setSaveErrorMessage(t('saveDialog.errorMessages.default'));
          setSaving(false);
        });
    }
  };

  const actions = existingAssessment ? (
    <>
      <Button onClick={() => save(globalAssessmentState)}>{t('saveDialog.overwrite')}</Button>
      <Button
        onClick={() => {
          const newAssessmentId = crypto.randomUUID();
          setAssessmentId(newAssessmentId);
          setSavedAuthor(email);
          const newGlobalAssessmentState = {
            ...globalAssessmentState,
            assessmentId: newAssessmentId,
            author: email
          };
          setSharedUsers([]);
          save(newGlobalAssessmentState);
        }}>{t('saveDialog.saveAsNew')}</Button>
    </>
  ) : (
    <Button onClick={() => save(globalAssessmentState)}>
      {t('saveDialog.save')}
    </Button>
  );

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby='save-dialog-title'
      aria-describedby='save-dialog-description'
    >
      <DialogTitle id='save-dialog-title'>{t('saveDialog.saveAssessment')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='save-dialog-description'>{globalAssessmentState.assessed.completed
          ? t('saveDialog.saveSpecies', { species: globalAssessmentState.assessed.scientificName })
          : t('saveDialog.cannotSave')}
        </DialogContentText>
      </DialogContent>
      {saveErrorMessage ? <Alert severity='error'>{t('saveDialog.couldNotSave', { error: saveErrorMessage })}</Alert> : null}
      {saving ? <LinearProgress /> : (
        <DialogActions>
          <Button onClick={closeDialog}>{t('saveDialog.cancel')}</Button>
          {globalAssessmentState.assessed.completed
            ? actions
            : null}
        </DialogActions>
      )}
    </Dialog>
  );
}

SaveDialog.propTypes = {
  t: PropTypes.func.isRequired,
  /** The obj to get saved with the exception of graphics and lastModified (determined upon save) */
  globalAssessmentState: PropTypes.shape({
    ccviVersion: PropTypes.string.isRequired,
    assessmentId: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    assessed: PropTypes.object.isRequired,
    surveyAnswers: PropTypes.objectOf(PropTypes.number).isRequired,
    exposureResults: PropTypes.object.isRequired,
    exposureStatus: PropTypes.string.isRequired
  }).isRequired,
  graphicsLayer: PropTypes.instanceOf(GraphicsLayer),
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setAssessmentId: PropTypes.func.isRequired,
  setSavedAuthor: PropTypes.func.isRequired,
  existingAssessment: PropTypes.bool.isRequired,
  setExistingAssessment: PropTypes.func.isRequired,
  setSharedUsers: PropTypes.func.isRequired,
};

export default withTranslation('translations')(SaveDialog);
