import React, { useState, useContext, useMemo } from 'react';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { withTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import TitleBar from './TitleBar';
import questions from '../utils/Questions';
import QuestionModule from './ModuleGroups/QuestionModule';
import ModuleSummary from './ModuleSummary';
import Scoring from './Scoring';
import { QUESTION_UNKNOWN_VALUE, ADAPTIVE_CAPACITY_MODULES, INSUFFICIENT_EVIDENCE, RCP_VALUES } from '../utils/Consts';
import AssessmentForm from './AssessmentForm';
import SimpleTabPanel from './SimpleTabPanel';
import ModuleA from './ModuleGroups/ModuleA';
import Footer from './Footer';
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import { useAuth } from '../AuthContext';
import classNames from 'classnames';
import './Modules.css';
import { MapViewContext } from 'react-mapcore-library';
import { exposureStatuses } from '../utils/Consts';
import Logos from './Logos';

function Modules({ t }) {

  /** Survey answers and dependent values: */

  const [surveyAnswers, setSurveyAnswers] = useState(Object.fromEntries(
    Object.entries(questions).flatMap((entry) => {
      const details = entry[1];
      return details.questions.map(
        (question) => [question.label, QUESTION_UNKNOWN_VALUE],
      );
    }),
  ));
  const changeSurveyAnswer = (surveyQuestionId, value) => {
    setSurveyAnswers((curVal) => ({ ...curVal, [surveyQuestionId]: value }));
  };

  const climateExposureModifier = useMemo(() => {
    const sectionBQuestions = questions.B.questions.map((question) => question.label);
    let sum = 0;
    let notAllQuestionsAnswered = false;
    sectionBQuestions.forEach((question) => {
      const answer = surveyAnswers[question];
      if (answer >= 0) {
        sum += answer;
      } else {
        notAllQuestionsAnswered = true;
      }
    });
    if (notAllQuestionsAnswered) {
      return INSUFFICIENT_EVIDENCE;
    }
    const total = sectionBQuestions.length * (questions.B.scale.options.length - 1);
    return sum / total;
  }, [surveyAnswers]);

  const adaptiveCapacityModifier = useMemo(() => {
    const sectionJQuestions = questions.J.questions.map((question) => question.label);
    let sum = 0;
    let answeredQuestions = 0;
    sectionJQuestions.forEach((question) => {
      const answer = surveyAnswers[question];
      if (answer >= 0) {
        answeredQuestions ++;
        sum += answer;
      }
    });

    if (answeredQuestions < 3) {
      return INSUFFICIENT_EVIDENCE;
    }

    const total = sectionJQuestions.length * (questions.J.scale.options.length - 1);
    return 1 + 0.5 * (sum / total);
  }, [surveyAnswers]);

  const observedModeledResponseScore = useMemo(() => {
    const sectionKQuestions = questions.K.questions.map((question) => question.label);
    let sum = 0;
    let answeredQuestions = 0;
    sectionKQuestions.forEach((question) => {
      const answer = surveyAnswers[question];
      if (answer >= 0) {
        sum += answer;
        answeredQuestions++;
      }
    });

    return answeredQuestions > 0 ? sum / (answeredQuestions * (questions.K.scale.options.length - 1)) : INSUFFICIENT_EVIDENCE;
  }, [surveyAnswers]);

  const adaptiveCapacity = useMemo(() => {
    let newAdaptiveCapacity = 0;
    let enteredModules = 0;
    ADAPTIVE_CAPACITY_MODULES.forEach((module) => {
      let moduleHasResponse = false;
      let answeredQuestions = 0;
      let moduleSubscore = 0;
      const sectionQuestions = questions[module].questions;
      sectionQuestions.forEach((question) => {
        if (surveyAnswers[question.label] >= 0) {
          moduleHasResponse = true;
          answeredQuestions++;

          moduleSubscore += surveyAnswers[question.label];
        }
      });
      if (moduleHasResponse) {
        newAdaptiveCapacity += moduleSubscore / (answeredQuestions * (questions[module].scale.options.length - 1));
        enteredModules++;
      }
    });
    if (enteredModules < ADAPTIVE_CAPACITY_MODULES.length - 1) {
      newAdaptiveCapacity = INSUFFICIENT_EVIDENCE;
    }
    return newAdaptiveCapacity === 0 ? 0.01 : newAdaptiveCapacity;
  }, [surveyAnswers]);


  /* Assessed Form values: */

  const [assessed, setAssessed] = useState({
    geographicArea: '',
    assessor: '',
    scientificName: '',
    commonName: '',
    population: '',
    kingdom: '',
    gRank: '',
    majorTaxonomicGroup: '',
    sRank: '',
    assessmentNotes: '',
    completed: false
  });
  const changeAssessed = (key, value) => {
    setAssessed((curVal) => {
      const newVal = { ...curVal, [key]: value };
      newVal.completed = Boolean(newVal.geographicArea && newVal.scientificName && newVal.kingdom && newVal.majorTaxonomicGroup);
      return newVal;
    });
  };


  /* Exposure and map-related values: */

  const [graphicsLayer] = React.useState(() => new GraphicsLayer({ listMode: "hide" }));
  const [exposureResults, setExposureResults] = React.useState([INSUFFICIENT_EVIDENCE, INSUFFICIENT_EVIDENCE]);
  const [exposureStatus, setExposureStatus] = React.useState(exposureStatuses.NO_AREA_TO_CALCULATE);
  const {mapView} = useContext(MapViewContext);

  const traitScores = useMemo(() => {
    return exposureResults.map((climateExposure) => {
      if ([climateExposure,
          climateExposureModifier,
          adaptiveCapacity,
          adaptiveCapacityModifier
        ].indexOf(INSUFFICIENT_EVIDENCE) !== -1) {
        return INSUFFICIENT_EVIDENCE;
      }
      const modifiedAdaptiveCapacity = adaptiveCapacity * adaptiveCapacityModifier;
      const overallExposure = 1 + climateExposure + climateExposureModifier;
      return overallExposure * modifiedAdaptiveCapacity;
    });
  }, [exposureResults, climateExposureModifier, adaptiveCapacity, adaptiveCapacityModifier]);


  /* Saving and loading: */

  const [assessmentId, setAssessmentId] = React.useState(crypto.randomUUID());
  const { email } = useAuth();
  const [savedAuthor, setSavedAuthor] = React.useState(email);

  // TODO: it would make sense to save results as an array since we now store them that way in the frontend,
  // but until the backend is updated, convert exposure results to an object, before saving:
  const exposureResultsToSave = RCP_VALUES.reduce((acc, rcpValue, idx) => (
    { ...acc, [rcpValue]: exposureResults[idx] }
  ), {});

  // Most of the state of the assessment (obj that gets loaded/saved), with the exception of
  // "graphics" and "lastModified", which are determined at the time of save:
  const globalAssessmentState = {
    ccviVersion: '0.1.0',
    assessmentId: assessmentId,
    author: savedAuthor || email,
    assessed: assessed,
    surveyAnswers: surveyAnswers,
    exposureResults: exposureResultsToSave,
    exposureStatus: exposureStatus
  };
  // Don't allow saving the status of "CALCULATING":
  if (exposureStatus === exposureStatuses.CALCULATING) {
    globalAssessmentState.exposureStatus = exposureStatuses.READY_TO_CALCULATE;
  }

  const setAllFormState = (globalAssessmentState) => {
    setAssessmentId(globalAssessmentState.assessmentId);
    setSavedAuthor(globalAssessmentState.author);
    setAssessed(globalAssessmentState.assessed);
    setSurveyAnswers(globalAssessmentState.surveyAnswers);

    graphicsLayer.graphics.removeAll();
    graphicsLayer.graphics.addMany(globalAssessmentState.graphics.map((graphic) => Graphic.fromJSON(graphic)));
    setExposureResults(RCP_VALUES.map(rcpValue => Number(globalAssessmentState.exposureResults[rcpValue])));
    setExposureStatus(globalAssessmentState.exposureStatus);
    if (mapView) mapView.goTo(graphicsLayer.graphics);
  };


  /* Tabs: */

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (_, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const moduleTabsDisabled = !assessed.completed;

  let tabName = 'assessorAssessedForm';
  let tabs = [<Tab
    key={tabName}
    label={t(`tabBar.${tabName}`)}
    id={`module-tab-${tabName}`}
    aria-controls={`module-tabpanel-${tabName}`} />];
  tabName = 'module-A';
  tabs.push(<Tab
    key={tabName}
    label={t(`tabBar.${tabName}`)}
    disabled={moduleTabsDisabled}
    id={`module-tab-${tabName}`}
    aria-controls={`module-tabpanel-${tabName}`} />);
  Object.keys(questions).forEach((moduleSection) => {
    tabName = `module-${moduleSection}`;
    tabs.push(
      <Tab
        key={tabName}
        label={t(`tabBar.${tabName}`)}
        disabled={moduleTabsDisabled}
        style={{ pointerEvents: 'auto' }}
        id={`module-tab-${tabName}`}
        aria-controls={`module-tabpanel-${tabName}`} />);
  });
  tabName = 'moduleSummary';
  tabs.push(<Tab
    key={tabName}
    label={t(`tabBar.${tabName}`)}
    disabled={moduleTabsDisabled}
    style={{ pointerEvents: 'auto' }}
    id={`module-tab-${tabName}`}
    aria-controls={`module-tabpanel-${tabName}`} />);
  tabName = 'scoring';
  tabs.push(<Tab
    key={tabName}
    label={t(`tabBar.${tabName}`)}
    disabled={moduleTabsDisabled}
    style={{ pointerEvents: 'auto' }}
    id={`module-tab-${tabName}`}
    aria-controls={`module-tabpanel-${tabName}`} />);


  /* render: */

  return (
    <div className={classNames(tabIndex === 1 ? 'module-a-selected' : 'module-a-not-selected')}>
      <TitleBar
        globalAssessmentState={globalAssessmentState}
        graphicsLayer={graphicsLayer}
        setAssessmentId={setAssessmentId}
        setSavedAuthor={setSavedAuthor}
        setAllFormState={setAllFormState}
      />
      <Box>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant='scrollable'
          scrollButtons='auto'
        >
          {tabs}
        </Tabs>
        <SimpleTabPanel
          value={tabIndex}
          index={0}
          role='tabpanel'
          id={'module-tabpanel-assessorAssessedForm'}
          aria-labelledby={'module-tab-assessorAssessedForm'}
        >
          <div className="content-container">
            <AssessmentForm
              assessed={assessed}
              changeAssessed={changeAssessed}
              changeSurveyAnswer={changeSurveyAnswer}
            />
          </div>
          <Logos />
        </SimpleTabPanel>
        <SimpleTabPanel
          value={tabIndex}
          index={1}
          role='tabpanel'
          id={'module-tabpanel-module-A'}
          aria-labelledby={'module-tab-module-A'}
          unmountWhenHidden={false}
        >
          <ModuleA
            t={t}
            graphicsLayer={graphicsLayer}
            exposureResults={exposureResults}
            setExposureResults={setExposureResults}
            exposureStatus={exposureStatus}
            setExposureStatus={setExposureStatus}
          />
        </SimpleTabPanel>
        {Object.keys(questions).map((moduleSection, moduleIndex) => (
          // + 1 to account for assessor/assessed form
          <SimpleTabPanel
            key={moduleSection}
            value={tabIndex}
            index={moduleIndex + 2}
            role='tabpanel'
            id={`module-tabpanel-module-${moduleSection}`}
            aria-labelledby={`module-tab-module-${moduleSection}`}
          >
            <QuestionModule
              questions={questions[moduleSection].questions}
              scale={questions[moduleSection].scale}
              assessed={assessed}
              surveyAnswers={surveyAnswers}
              changeSurveyAnswer={changeSurveyAnswer}
              isAdaptiveCapacity={ADAPTIVE_CAPACITY_MODULES.includes(moduleSection)}
              adaptiveCapacity={adaptiveCapacity}
            />
          </SimpleTabPanel>
        ))}
        <SimpleTabPanel
          value={tabIndex}
          index={2 + Object.keys(questions).length}
          role='tabpanel'
          id={'module-tabpanel-moduleSummary'}
          aria-labelledby={'module-tab-moduleSummary'}
        >
          <div className="content-container">
            <ModuleSummary
              surveyAnswers={surveyAnswers}
              exposureResults={exposureResults}
            />
          </div>
        </SimpleTabPanel>
        <SimpleTabPanel
          value={tabIndex}
          index={3 + Object.keys(questions).length}
          role='tabpanel'
          id={'module-tabpanel-scoring'}
          aria-labelledby={'module-tab-scoring'}
        >
          <div className="content-container">
            <Scoring
              exposureResults={exposureResults}
              climateExposureModifier={climateExposureModifier}
              adaptiveCapacity={adaptiveCapacity}
              adaptiveCapacityModifier={adaptiveCapacityModifier}
              traitScores={traitScores}
              observedModeledResponseScore={observedModeledResponseScore}
            />
          </div>
        </SimpleTabPanel>
        <Footer tabCount={tabs.length} tabIndex={tabIndex} setTabIndex={setTabIndex} cannotContinue={moduleTabsDisabled} />
      </Box>
    </div>
  );
}

Modules.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('translations')(Modules);
